import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Welton7images from "./wellton7.js";
import Welton8images from "./wellton8.js";
import Welton9images from "./wellton9.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Dayanawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Welton%207)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Amandawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Welton%208)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Cynthiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Welton%209)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Welton7images.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Welton 7</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 98m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 118m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Dayanawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Welton8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Welton 8</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">19</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 128m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 166m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Amandawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Welton9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} alt={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Welton 9</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Free DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">28</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 162m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 266m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 6+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cynthiawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
