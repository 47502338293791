import React from "react";
import "./home.scss";
import Navbar from "../../src/Section/Navbar/navbar.jsx";
import Banner from "../../src/Section/Banner/banner.jsx";
import About from "../../src/Section/About/about.jsx";
import Newlaunch from "../../src/Section/Newlaunching/newlaunching.jsx";
import Promo from "../../src/Section/Promo/promo.jsx";
import Rumah from "../../src/Section/Rumah/rumah.jsx";
import OtherBSD from "../../src/Section/Other BSD/Other BSD.jsx";
import Future from "../../src/Section/Future/future.jsx";
import Lokasi from "../../src/Section/Lokasi/lokasi.jsx";
import Footer from "../../src/Section/Footer/footer.jsx";
import tombol from "../media/tombol.webp";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Hiera%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Newlaunch />
      <Promo />
      <Rumah />
      <OtherBSD />
      <Future />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
