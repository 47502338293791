import Elyon91 from '../Elyon 9/Elyon 9 (1).webp'
import Elyon92 from '../Elyon 9/Elyon 9 (2).webp'
import Elyon93 from '../Elyon 9/Elyon 9 (3).webp'
import Elyon94 from '../Elyon 9/Elyon 9 (4).webp'
import Elyon95 from '../Elyon 9/Elyon 9 (5).webp'
import Elyon96 from '../Elyon 9/Elyon 9 (6).webp'
import Elyon97 from '../Elyon 9/Elyon 9 (7).webp'
import Elyon98 from '../Elyon 9/Elyon 9 (8).webp'
import Elyon99 from '../Elyon 9/Elyon 9 (9).webp'
import Elyon910 from '../Elyon 9/Elyon 9 (10).webp'
import Elyon911 from '../Elyon 9/Elyon 9 (11).webp'
import Elyon912 from '../Elyon 9/Elyon 9 (12).webp'
import Elyon913 from '../Elyon 9/Elyon 9 (13).webp'

const Elyon9images =[ Elyon91,Elyon92,Elyon93,Elyon94,Elyon95,Elyon96,Elyon97,Elyon98,Elyon99,Elyon910,Elyon911,Elyon912,Elyon913
]

export default Elyon9images;