import React from "react";
import "./about.scss";

import logohiera from "../../media/logo_hiera.png";
import logosinarmas from "../../media/800px-Sinar_Mas_Land_Logo.png";
import ReactReadMoreReadLess from "react-read-more-read-less";

const aboutsumban = () => {
  const text =
    "Hiera Welton adalah sebuah proyek pengembangan perumahan di BSD City, Indonesia. Proyek ini merupakan hasil kerja sama antara Mitbana dan Sinar Mas Land dengan luas tanah 108 hektar. Hiera Welton BSD menawarkan konsep hunian “Well-Living at Hiera” yang memastikan penghuni mendapatkan hunian perumahan yang optimal dengan kenyamanan dan keamanan yang terjamin. Hiera Welton menawarkan konsep hunian yang mengutamakan kenyamanan dan keamanan penghuni. Cluster Welton di Hiera BSD menawarkan pengalaman hidup yang holistik dengan fasilitas yang lengkap, seperti taman, kolam renang, pusat kebugaran, dan area bermain anak-anak. Kawasan sekitar Hiera BSD menawarkan berbagai fasilitas, seperti pusat perbelanjaan, restoran, kafe, dan fasilitas olahraga.";

  return (
    <div id="about" className="about-hiera">
      <div className="containerlogohiera">
        <div className="logohiera">
          <img className="gambarhiera" src={logohiera} alt="logo hiera" />
        </div>
        <div className="logosinarmas">
          <img
            className="gambarsinarmas"
            src={logosinarmas}
            alt="logo sinarmas"
          />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>The Heartland of BSD City</h1>
        </div>
        <div className="aboutsubtitle">
          <ReactReadMoreReadLess
            charLimit={484}
            readMoreText={"Read more ▼"}
            readLessText={"Read less ▲"}>
            {text}
          </ReactReadMoreReadLess>
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
