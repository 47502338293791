import Armont1 from '../Armont/Armont  (1).webp'
import Armont2 from '../Armont/Armont  (2).webp'
import Armont3 from '../Armont/Armont  (3).webp'
import Armont4 from '../Armont/Armont  (4).webp'
import Armont5 from '../Armont/Armont  (5).webp'
import Armont6 from '../Armont/Armont  (6).webp'
import Armont7 from '../Armont/Armont  (7).webp'
import Armont8 from '../Armont/Armont  (8).webp'
import Armont9 from '../Armont/Armont  (9).webp'
import Armont10 from '../Armont/Armont  (10).webp'


const Armontimages =[ Armont1,Armont2,Armont3,Armont4,Armont5,Armont6,Armont7,Armont8,Armont9,Armont10
]

export default Armontimages;