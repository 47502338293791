import Terravia1 from '../Adora Primes/Terravia (1).webp'
import Terravia2 from '../Adora Primes/Terravia (2).webp'
import Terravia3 from '../Adora Primes/Terravia (3).webp'
import Terravia4 from '../Adora Primes/Terravia (4).webp'
import Terravia5 from '../Adora Primes/Terravia (5).webp'
import Terravia6 from '../Adora Primes/Terravia (6).webp'
import Terravia7 from '../Adora Primes/Terravia (7).webp'
import Terravia8 from '../Adora Primes/Terravia (8).webp'
import Terravia9 from '../Adora Primes/Terravia (9).webp'
import Terravia10 from '../Adora Primes/Terravia (10).webp'
import Terravia11 from '../Adora Primes/Terravia (11).webp'
import Terravia12 from '../Adora Primes/Terravia (12).webp'
import Terravia13 from '../Adora Primes/Terravia (13).webp'
import Terravia14 from '../Adora Primes/Terravia (14).webp'
import Terravia15 from '../Adora Primes/Terravia (15).webp'


const Terraviaimages =[ Terravia1,Terravia2,Terravia3,Terravia4,Terravia5,Terravia6,Terravia7,Terravia8,Terravia9,Terravia10,Terravia11,Terravia12,Terravia13,Terravia14,Terravia15
]

export default Terraviaimages;