import React from "react";
import "./Other BSD.scss";
import Newlaunching1 from "./Other BSD/Other BSD1.jsx";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const rumah = () => {
  return (
    <div id="newlaunch" className="rumah1">
      <div className="judulnewlaunch">
        <div className="container-newlaunch1">
          <div className="logowynyard1">
            <h1>Other Clusters in BSD City</h1>
          </div>
        </div>
      </div>
      <div className="rumah-card">
        <Newlaunching1 />
      </div>
    </div>
  );
};

export default rumah;
